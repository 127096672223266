import axiosInstance from "auth/FetchInterceptor";
import { BANHANG_API } from "constants/ApiConstant";
const BanHangService = {};

BanHangService.searchGrid = function (data) {
  return axiosInstance.post(`${BANHANG_API}/search-grid`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};

BanHangService.getBanHangByMabanhang = function (data) {
  return axiosInstance.get(
    `${BANHANG_API}/get-by-mabanhang?mabanhang=${data.id}&idphieu=${data.idphieu}`,
    {
      baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
    }
  );
};

BanHangService.create = function (data) {
  return axiosInstance.post(BANHANG_API, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
BanHangService.update = function (data) {
  return axiosInstance.put(BANHANG_API, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
BanHangService.changeStateBanHang = function (data) {
  return axiosInstance.put(`${BANHANG_API}/change-state`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
BanHangService.deleteBanHang = function (id) {
  return axiosInstance.delete(
    `${BANHANG_API}/delete-by-mabanhang?mabanhang=${id}`,
    {
      baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
    }
  );
};
BanHangService.getBanHangCTByMabanhang = function (data) {
  return axiosInstance.post(`${BANHANG_API}/get-ct-by-mabanhang`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};

BanHangService.addBanHangCT = function (data) {
  return axiosInstance.post(`${BANHANG_API}/add-banhangct`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
BanHangService.updateBanHangCT = function (data) {
  return axiosInstance.post(`${BANHANG_API}/update-banhangct`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
BanHangService.deleteBanHangCT = function (data) {
  return axiosInstance.post(`${BANHANG_API}/delete-banhangct`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
BanHangService.getTonBanHang = function (data) {
  return axiosInstance.post(`${BANHANG_API}/get-ton-ban-hang`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
export default BanHangService;
