import { Col, Form, Input, Row, Select, DatePicker, Button } from "antd";
import { isEmpty } from "lodash";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import TableNhapKhoCTList from "./TableNhapKhoCTList";

import {
  createNhapXuat,
  getNhapXuatByManx,
  updateNhapXuat,
} from "store/slices/sale/nhapxuatSlice";

import {
  getDmDoiTuong,
  getDmKk,
  getDmLoaiPhieuNhapXuat,
  getDmThuocVatTu,
} from "store/slices/sale/danhmucSlice";

import SlipModalComponent from "components/SlipModalComponent";
import { DOCUMENT_TYPE_ID } from "constants";
import moment from "moment";
import { getNhapKhoCTByManx } from "store/slices/sale/nhapkhoSlice";
import { guidEmpty } from "constants";
// Filter `option.label` match the user type `input`
const filterOption = (input, option) =>
  (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

export default function ModalNhapKho({
  visibleModal,
  setVisibleModal,
  loading,
  nhapXuatDetail,
  setNhapKhoId,
  nhapKhoId,
  idPhieu,
  setIdPhieu,
  reloadData,
  nhapKhoCTList = [],
  permitList = [],
}) {
  const dispatch = useDispatch();
  const {
    dmKkList,
    dmLoaiPhieuNhapXuatList,
    dmDoiTuongList,
    dmThuocVatTuList,
  } = useSelector((state) => state.danhMucSale);
  useEffect(() => {
    dispatch(getDmDoiTuong());
    dispatch(getDmKk());
    dispatch(getDmLoaiPhieuNhapXuat());
    dispatch(getDmThuocVatTu());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  const [form] = Form.useForm();
  const childRef = useRef(null);
  useEffect(() => {
    if ((nhapKhoId === null || nhapKhoId === 0) && idPhieu === null) {
      return;
    }
    dispatch(
      getNhapXuatByManx({
        id: nhapKhoId ?? 0,
        idphieu: idPhieu ?? guidEmpty,
        onSuccess: (data) => {
          dispatch(
            getNhapKhoCTByManx({
              manx: data.data.manx,
              isNewCt: false,
            })
          );
        },
      })
    );
  }, [dispatch, nhapKhoId, idPhieu]);
  useEffect(() => {
    if ((nhapKhoId === null || nhapKhoId === 0) && idPhieu === null) {
      form.setFieldValue("ngay", moment());
      return;
    } else {
      form.setFieldsValue(nhapXuatDetail);
      form.setFieldValue("ngay", moment(nhapXuatDetail.ngay));
      if (!isEmpty(nhapXuatDetail.ngayhoadon)) {
        form.setFieldValue("ngayhoadon", moment(nhapXuatDetail.ngayhoadon));
      }
    }
  }, [form, nhapXuatDetail, nhapKhoId, idPhieu]);

  useEffect(() => {}, [nhapKhoId, permitList]);
  const onFinish = (values) => {
    const payload = {
      sophieu: values.sophieu,
      makk: values.makk,
      makkgiao: values.makkgiao,
      makknhan: values.makknhan,
      maloaiphieu: values.maloaiphieu,
      madoituong: values.madoituong,
      ngay: values.ngay,
      nguoigiao: values.nguoigiao,
      nguoinhan: values.nguoinhan,
      thue: values.thue,
      mahoadon: values.mahoadon,
      sohoadon: values.sohoadon,
      ngayhoadon: values.ngayhoadon,
      loai: 1,
      daduyet: values.daduyet,
      manvduyet: values.manvduyet,
      manv: values.manv,
      cachtinhthue: values.cachtinhthue,
      ghichu: values.ghichu,
      luanchuyen: false,
      onSuccess: ({ data }) => {
        // Refresh data after add/update table
        if (isEmpty(nhapKhoId)) {
          if (data.data.Id && data.data.MaPhieu && childRef.current) {
            childRef.current.signedDocumentCreate(
              data.data.Id,
              data.data.MaPhieu
            );
          }

          setNhapKhoId(data.data.MaNX);
        }

        reloadData();
      },
    };
    if (nhapKhoId !== null) {
      payload.manx = nhapKhoId;
      dispatch(updateNhapXuat(payload));
    } else {
      dispatch(createNhapXuat(payload));
    }
  };

  const renderInfo = () => {
    return (
      <Form form={form} layout="vertical" onFinish={onFinish} className="p-3">
        <Row gutter={16}>
          <Col sm={8} md={8} lg={8}>
            <Form.Item name="sophieu" label="Số phiếu">
              <Input placeholder="Tự động" readOnly />
            </Form.Item>
          </Col>
          <Col sm={8} md={8} lg={8}>
            <Form.Item
              name="ngay"
              label="Ngày nhập"
              rules={[
                {
                  required: true,
                  message: "Ngày lập phiếu là bắt buộc",
                },
              ]}
            >
              <DatePicker
                showTime
                style={{ width: "100%" }}
                placeholder={"Ngày nhập"}
                // format={{
                //   format: "DD/MM/YYYY HH:mm",
                //   type: "mask",
                // }}
              />
            </Form.Item>
          </Col>
          <Col sm={8} md={8} lg={8}>
            <Form.Item
              name="maloaiphieu"
              label="Loại phiếu"
              rules={[
                {
                  required: true,
                  message: "Vui lòng chọn loại phiếu",
                },
              ]}
            >
              <Select
                showSearch
                filterOption={filterOption}
                options={dmLoaiPhieuNhapXuatList?.filter((x) => x.maloai === 1)}
                placeholder="Chọn loại phiếu"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col sm={24} md={24} lg={24}>
            <Form.Item
              name="makk"
              label="Kho nhập"
              rules={[
                {
                  required: true,
                  message: "Vui lòng chọn kho nhập",
                },
              ]}
            >
              <Select
                showSearch
                filterOption={filterOption}
                options={dmKkList?.filter((x) => x.maloai === 1)}
                placeholder="Chọn kho nhập"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col sm={16} md={16} lg={16}>
            <Form.Item
              name="makkgiao"
              label="Đơn vị giao"
              rules={[
                {
                  required: true,
                  message: "Vui lòng chọn đơn vị giao",
                },
              ]}
            >
              <Select
                showSearch
                filterOption={filterOption}
                options={dmKkList?.filter((x) => x.maloai === 5)}
                placeholder="Chọn kho đơn vị giao"
              />
            </Form.Item>
          </Col>
          <Col sm={8} md={8} lg={8}>
            <Form.Item name="nguoigiao" label="Người giao">
              <Input placeholder="Nhập người giao" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col sm={16} md={16} lg={16}>
            <Form.Item
              name="makknhan"
              label="Đơn vị nhận"
              rules={[
                {
                  required: true,
                  message: "Vui lòng chọn đơn vị nhận",
                },
              ]}
            >
              <Select
                showSearch
                filterOption={filterOption}
                options={dmKkList}
                placeholder="Chọn kho đơn vị nhận"
              />
            </Form.Item>
          </Col>
          <Col sm={8} md={8} lg={8}>
            <Form.Item name="nguoinhan" label="Người nhận">
              <Input placeholder="Nhập người nhận" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col sm={8} md={8} lg={8}>
            <Form.Item name="madoituong" label="Đối tượng">
              <Select
                showSearch
                filterOption={filterOption}
                options={dmDoiTuongList}
                placeholder="Chọn đối tượng"
              />
            </Form.Item>
          </Col>
          <Col sm={8} md={8} lg={8}>
            <Form.Item name="thue" label="Thuế (%)">
              <Input placeholder="Thuế" type="number" />
            </Form.Item>
          </Col>
          <Col sm={8} md={8} lg={8}>
            <Form.Item name="ngayhoadon" label="Ngày hóa đơn">
              <DatePicker
                style={{ width: "100%" }}
                placeholder={"Ngày hóa đơn"}
                // format={{
                //   format: "DD/MM/YYYY",
                //   type: "mask",
                // }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col sm={8} md={8} lg={8}>
            <Form.Item name="mahoadon" label="Mã hóa đơn">
              <Input placeholder="Mã hóa đơn" />
            </Form.Item>
          </Col>
          <Col sm={8} md={8} lg={8}>
            <Form.Item name="sohoadon" label="Số hóa đơn">
              <Input placeholder="Số hóa đơn" />
            </Form.Item>
          </Col>
          <Col sm={8} md={8} lg={8}>
            <Form.Item name="ghichu" label="Ghi chú">
              <Input placeholder="Nhập ghi chú" />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col sm={24}>
            <Button
              type="primary"
              onClick={() => {
                form.submit();
              }}
            >
              Lưu
            </Button>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col sm={24} md={24} lg={0}>
            <Form.Item name="daduyet" label="Đã duyệt" hidden>
              <Input></Input>
            </Form.Item>
          </Col>
          <Col sm={24} md={24} lg={0}>
            <Form.Item name="manvduyet" label="Mã nhân viên duyệt" hidden>
              <Input></Input>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <SlipModalComponent
      ref={childRef}
      maskClosable={false}
      bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 300px)" }}
      width={1400}
      title={!isEmpty(nhapKhoId) ? "Thêm phiếu nhập kho" : "Sửa phiếu nhập kho"}
      objectId={nhapXuatDetail?.idphieu}
      objectType={"PhieuNhapKho"}
      widthInfo={550}
      documentTypeId={DOCUMENT_TYPE_ID.NhapHang}
      titleTLink="Phiếu nhập kho"
      descriptionTLink={nhapXuatDetail?.nhapKhoId}
      isVisibleModal={visibleModal}
      onRenderTab={[
        {
          label: "Thông tin chi tiết",
          children: (
            <TableNhapKhoCTList
              setNhapKhoId={setNhapKhoId}
              nhapKhoCTList={nhapKhoCTList}
              reloadData={() => reloadData()}
              setVisibleModal={setVisibleModal}
              visibleModal={visibleModal}
              dmThuocVatTuList={dmThuocVatTuList}
              permitList={permitList}
              nhapKhoId={nhapKhoId}
            />
          ),
        },
      ]}
      onSignReloadForm={(data) => {
        if (data.totalSigned === data.totalSigns) {
          dispatch(
            getNhapXuatByManx({
              id: nhapKhoId ?? 0,
              idphieu: idPhieu ?? guidEmpty,
            })
          );
          reloadData();
        }
      }}
      onRenderInfo={() => renderInfo()}
      onCancelModal={() => {
        setVisibleModal(!visibleModal);
        form.resetFields();
        setNhapKhoId(0);
      }}
    />
  );
}
