import axiosInstance from "auth/FetchInterceptor";
import {
  EMPLOYEE_GRID_API,
  EMPLOYEE_SEARCH_EMPLOYEE_API,
  DETAIL_API,
  EMPLOYEE_API,
  CHANGE_STATE_EMPLOYEE_API,
  EXPORT_EXCEL_EMPLOYEE_API,
  IMPORT_EXCEL_EMPLOYEE_API,
  IMPORT_DSNV_EMPLOYEE_API,
  IMPORT_HISTORY_API,
  IMPORT_EMPLOYEE_APPLIER_API,
} from "constants/ApiConstant";
const EmployeeService = {};

EmployeeService.searchGrid = function (data) {
  return axiosInstance.post(EMPLOYEE_GRID_API, data);
};

EmployeeService.searchEmployees = function (data) {
  return axiosInstance.post(EMPLOYEE_SEARCH_EMPLOYEE_API, data);
};

EmployeeService.detail = function (id) {
  return axiosInstance.get(`${DETAIL_API}/${id}`, null);
};

EmployeeService.importDsnv = function (id) {
  return axiosInstance.get(`${IMPORT_DSNV_EMPLOYEE_API}?transId=${id}`);
};

EmployeeService.importHistory = function (data) {
  return axiosInstance.get(
    `${IMPORT_HISTORY_API}?branchId=${data.branchId}&&type=${data.type}`
  );
};

EmployeeService.create = function (data) {
  return axiosInstance.post(EMPLOYEE_API, data);
};

EmployeeService.exportExcel = function (data) {
  return axiosInstance.post(EXPORT_EXCEL_EMPLOYEE_API, data);
};

EmployeeService.importExcel = function (data) {
  return axiosInstance.post(IMPORT_EXCEL_EMPLOYEE_API, data);
};

EmployeeService.importDsnvApplier = function (data) {
  return axiosInstance.post(IMPORT_EMPLOYEE_APPLIER_API, data);
};

EmployeeService.update = function (data) {
  return axiosInstance.put(EMPLOYEE_API, data);
};

EmployeeService.changeState = function (data) {
  return axiosInstance.put(CHANGE_STATE_EMPLOYEE_API, data);
};

EmployeeService.delete = function (data) {
  return axiosInstance.delete(`${EMPLOYEE_API}/delete?id=${data.id}&type=${data.type}`, null);
};

EmployeeService.getAllSalary = function (id) {
  return axiosInstance.get(`${EMPLOYEE_API}/salary/get-all?id=${id}`, null);
};

EmployeeService.getAllWorkProcess = function (id) {
  return axiosInstance.get(
    `${EMPLOYEE_API}/work-process/get-all?id=${id}`,
    null
  );
};

EmployeeService.getWorkPosition = function (data) {
  return axiosInstance.get(`${EMPLOYEE_API}/work-position-by-id?id=${data.id}&type=${data.type}`, data);
};

EmployeeService.getAllChangeHistory = function (data) {
  return axiosInstance.get(
    `${EMPLOYEE_API}/change-history/get-all?id=${data.id}&&type=${data.type}`,
    null
  );
};

EmployeeService.upSertSalary = function (data) {
  return axiosInstance.post(`${EMPLOYEE_API}/salary/up-sert`, data);
};

EmployeeService.upSertWorkProcess = function (data) {
  return axiosInstance.post(`${EMPLOYEE_API}/work-process/up-sert`, data);
};

EmployeeService.exportTLLuongExcelEmployee = function (data) {
  return axiosInstance.post(`${EMPLOYEE_API}/export-thiet-lap-luong`, data);
};

EmployeeService.importTLLuongEmployee = function (data) {
  return axiosInstance.post(`${EMPLOYEE_API}/import-thiet-lap-luong`, data);
};
export default EmployeeService;
