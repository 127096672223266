import { Button, Input, InputNumber, Popconfirm, Table, Form } from "antd";

import React, { useEffect, useMemo, useState } from "react";
import { styled } from "styled-components";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  SaveOutlined,
  LockOutlined,
  UnlockOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import Utils from "utils";
import { permitKey, PermitValue } from "constants";
import {
  createDmthuocvattu,
  getAllDmthuocvattu,
  setAllDmthuocvattuList,
  updateDmthuocvattu,
  deleteDmthuocvattu,
} from "store/slices/sale/danhmucSlice";

import { initDmthuocvattu } from "views/app-views/sale/category/Dmthuocvattu";
import { isEmpty } from "lodash";
const formater = new Intl.NumberFormat("vi-VN");
export const formatterNumber = (val) => {
  if (!val) return 0;
  return formater.format(val);
};

export const parserNumber = (val) => {
  if (!val) return 0;
  return Number.parseFloat(
    val.replace(/\$\s?|(\.*)/g, "").replace(/(\,{1})/g, ".")
  ).toFixed(5);
};

const EditableCell = ({
  editing,
  dataIndex,
  title,
  record,
  index,
  children,
  inputType,
  ...restProps
}) => {
  const inputNode =
    inputType === "number" ? (
      <InputNumber formatter={parserNumber} />
    ) : (
      <Input />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: dataIndex === "name",
              message: `Trường này là bắt buộc!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const TableDmthuocvattuList = ({
  allDmthuocvattuList,
  reloadData,
  permitList = [],
}) => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isEditing = (record) =>
    record.action === "initial"
      ? record.action === editingKey
      : record.mahh === editingKey;

  const dispatch = useDispatch();

  const allowLock = Utils.checkPermitValue(
    PermitValue.khoa,
    permitList,
    permitKey.crm_hospital
  );
  const allowEdit = Utils.checkPermitValue(
    PermitValue.sua,
    permitList,
    permitKey.crm_hospital
  );
  const allowDelete = Utils.checkPermitValue(
    PermitValue.xoa,
    permitList,
    permitKey.crm_hospital
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const edit = (record) => {
    form.setFieldsValue({
      mahh: null,
      tenhh: null,
      sapxep: null,
      ...record,
    });
    setEditingKey(record.action === "initial" ? record.action : record.mahh);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const save = async (key) => {
    try {
      const row = await form.validateFields();
      console.log("row >> ", { ...row });
      const newData = allDmthuocvattuList.slice(0);
      const index = newData.findIndex((item) =>
        item.mahh ? item.mahh === key : item.action === key
      );
      const item = newData[index];
      const payload = {
        mahh: item.mahh || null,
        tenhh: item.tenhh,
        viettat: item.viettat,
        sapxep: item.sapxep,
        ...row,
        onSuccess: () => {
          dispatch(getAllDmthuocvattu());
          setEditingKey("");
        },
      };

      if (key === "initial") {
        dispatch(createDmthuocvattu(payload));
      } else {
        dispatch(updateDmthuocvattu(payload));
      }
    } catch (error) {}
  };

  const tableColumns = useMemo(
    () => [
      {
        title: "STT",
        align: "center",
        render: (_, __, index) => index + 1,
      },
      {
        title: "Mã số",
        dataIndex: "mahh",
      },
      {
        title: "Mã view",
        dataIndex: "maview",
      },
      {
        title: "Tên",
        dataIndex: "tenhh",
        editable: true,
      },
      {
        title: "ĐVT",
        dataIndex: "tendonvitinh",
      },
      {
        title: "Hàm lượng",
        dataIndex: "hamluong",
      },
      {
        title: "Số ĐK",
        dataIndex: "sodangky",
      },
      {
        title: "Đơn giá",
        dataIndex: "sapxep",
        editable: true,
      },
      {
        fixed: "right",
        align: "center",
        dataIndex: "action",
        render: (_, record) => {
          const editable = isEditing(record);
          return editable ? (
            <>
              <Button
                onClick={() => save(record?.mahh || "initial")}
                className="mr-2"
                icon={<SaveOutlined />}
                shape="circle"
              />
              <Button
                onClick={() => setEditingKey(null)}
                className="mr-2"
                icon={<CloseOutlined />}
                shape="circle"
              />
            </>
          ) : (
            <>
              <Button
                title={
                  record.action === "initial"
                    ? "Thêm thông tin"
                    : "Sửa thông tin"
                }
                onClick={() => edit(record)}
                className="mr-2"
                icon={
                  record.action === "initial" ? (
                    <PlusOutlined />
                  ) : (
                    <EditOutlined />
                  )
                }
                shape="circle"
              />
              {record.mahh && (
                <>
                  <Popconfirm
                    title={"Khóa/Mở khóa hồ sơ"}
                    placement="topLeft"
                    onConfirm={() => {}}
                  >
                    <Button
                      title={"Khóa/Mở khóa hồ sơ"}
                      className="mr-2"
                      icon={
                        record.status ? (
                          <UnlockOutlined type="primary" />
                        ) : (
                          <LockOutlined type="primary" />
                        )
                      }
                      shape="circle"
                    />
                  </Popconfirm>
                  <Popconfirm
                    title="Bạn có muốn xóa không?"
                    placement="topLeft"
                    onConfirm={() => {}}
                  >
                    <Button
                      title={"Xóa thông tin"}
                      className="mr-2"
                      icon={<DeleteOutlined type="primary" />}
                      shape="circle"
                    />
                  </Popconfirm>
                </>
              )}
            </>
          );
        },
      },
    ],
    [edit, isEditing, save]
  );

  const mergedColumns = tableColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "sapxep" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        columns={mergedColumns}
        dataSource={allDmthuocvattuList}
        rowKey={(item) => item?.mahh}
        bordered
        style={{ padding: "0px" }}
        fixed={true}
        scroll={{ x: "max-content" }}
      />
    </Form>
  );
};

export default TableDmthuocvattuList;
