import React, { useEffect, useRef, useState } from "react";
import TableNhapKhoList from "./TableNhapKhoList";
import ModalNhapKho from "./ModalNhapKho";
import { useDispatch, useSelector } from "react-redux";
import { getDSNhapXuat } from "store/slices/sale/nhapxuatSlice";
import moment from "moment";
import { SystemButtonSource } from "constants";
import MeditechTablePage, { FilterComponent } from "components/table-layout";
const initSearch = {
  startDate: moment().startOf("month"),
  endDate: moment().endOf("month"),
  searchText: null,
  loai: 1,
  luanchuyen: false,
};
export const initNhapKhoCt = {
  action: "initial",
  isRequired: true,
};
const NhapKhoList = ({ queryId, openModalDetail }) => {
  const dispatch = useDispatch();
  const [visibleModal, setVisibleModal] = useState(false);
  const { permitList } = useSelector((state) => state.auth);
  const [nhapKhoId, setNhapKhoId] = useState(null);
  const [idPhieu, setIdPhieu] = useState(null);
  const { nhapXuatList, loading, nhapXuatDetail } = useSelector(
    (state) => state.nhapXuatSale
  );
  const searchFormRef = useRef({ ...initSearch });
  const [searchFormData, setSearchFormData] = useState(searchFormRef.current);
  const { nhapKhoCTList } = useSelector((state) => state.nhapKhoSale);

  useEffect(() => {
    if (openModalDetail) {
      setVisibleModal(openModalDetail);
      setIdPhieu(queryId);
    }
  }, [queryId, openModalDetail]);

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const reloadData = () => {
    dispatch(getDSNhapXuat(searchFormRef.current));
  };

  const searchHandle = (dataSearch) => {
    searchFormRef.current = { ...searchFormRef.current, ...dataSearch };
    setSearchFormData({ ...searchFormRef.current });
    reloadData();
  };
  const onRenderSearchView = () => {
    return (
      <FilterComponent
        datasource={searchFormData}
        onSearchImmediate={searchHandle}
        renderInputSearch
        renderDatePicker
      >
        {/* <Select
          key="trangThai"
          data-field="trangThai"
          style={{ width: "100%", minWidth: 200 }}
          placeholder="Trạng thái"
          options={listTrangThai}
          allowClear
          mode="multiple"
        /> */}
      </FilterComponent>
    );
  };

  const onDeleteHandler = (id) => {};

  const onOpenDetailHandler = (id) => {
    //setDoanKhamId(id);
    setVisibleModal(true);
  };

  const getRightButton = (isLocked) => {
    const arr = [];
    if (isLocked) {
      arr.push({
        keyName: SystemButtonSource.addBtn,
        title: "Thêm",
        showMore: false,
        // disable: !allowAddNew,
      });
    }
    return arr;
  };

  const onRightTopBtnHandler = (key) => {
    switch (key) {
      case SystemButtonSource.addBtn:
        setVisibleModal((prev) => !prev);
        break;
      default:
        break;
    }
  };
  return (
    <>
      <MeditechTablePage
        tableTitle="Quản lý nhập kho"
        btnHeaderRightSource={getRightButton(true)}
        btnHeaderRightSourceHandle={onRightTopBtnHandler}
        onRenderSearchView={onRenderSearchView}
      >
        <TableNhapKhoList
          loading={loading}
          setNhapKhoId={setNhapKhoId}
          nhapXuatList={nhapXuatList}
          reloadData={() => reloadData()}
          setVisibleModal={setVisibleModal}
          visibleModal={visibleModal}
          permitList={permitList}
          onOpenDetail={onOpenDetailHandler}
          onDelete={onDeleteHandler}
        />
      </MeditechTablePage>

      <ModalNhapKho
        visibleModal={visibleModal}
        loading={false}
        setVisibleModal={setVisibleModal}
        nhapKhoId={nhapKhoId}
        setNhapKhoId={setNhapKhoId}
        idPhieu={idPhieu}
        setIdPhieu={setIdPhieu}
        nhapXuatDetail={nhapXuatDetail}
        reloadData={() => reloadData()}
        permitList={permitList}
        nhapKhoCTList={nhapKhoCTList}
      />
    </>
  );
};

export default NhapKhoList;
