import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import store from "./store";
import history from "./history";
import Layouts from "./layouts";
import { THEME_CONFIG } from "./configs/AppConfig";
import "./lang";
import viLocale from "moment/locale/vi";
import moment from "moment";
import { generateToken, onMessageListener } from "auth/FirebaseAuth";
import { Avatar, notification } from "antd";
import { getMessaging, onMessage, deleteToken } from "firebase/messaging";
import { getNoti } from "store/slices/common/erpNotificationSlice";
import {
  MailOutlined,
  WarningOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";

moment.updateLocale("vi", viLocale);

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {
  const messaging = getMessaging();
  onMessage(messaging, (payload) => {
    console.log(payload, "NOTIFICATION LOG");
    notification.info({
      message: payload.notification.title,
      description: payload.notification.body,
      duration: 10,
      icon: (
        <Avatar
          src={payload.data.imageUrl ? payload.data.imageUrl : <MailOutlined />}
        />
      ),
    });
  });

  return (
    <div className="App">
      <Provider store={store}>
        <BrowserRouter history={history}>
          <ThemeSwitcherProvider
            themeMap={themes}
            defaultTheme={THEME_CONFIG.currentTheme}
            insertionPoint="styles-insertion-point"
          >
            <Layouts />
          </ThemeSwitcherProvider>
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
