import { Button, Popconfirm, Table, Tag } from "antd";
import React, { useMemo } from "react";
import { styled } from "styled-components";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import Utils from "utils";
import { deleteNhapXuat } from "store/slices/sale/nhapxuatSlice";

export default function TablenhapKhoList({
  setNhapKhoId,
  nhapXuatList,
  setVisibleModal,
  visibleModal,
  reloadData,
  permitList = [],
}) {
  const dispatch = useDispatch();

  const tableColumns = useMemo(
    () => [
      {
        title: "STT",
        width: 50,
        align: "center",
        render: (_, __, index) => index + 1,
      },
      {
        title: "Số phiếu",
        width: 90,
        dataIndex: "sophieu",
      },
      {
        title: "Ngày",
        width: 120,
        dataIndex: "ngay",
        render: (value) => Utils.formatDate(value),
      },
      {
        title: "Kho nhập",
        width: 160,
        dataIndex: "tenkk",
      },
      {
        title: "Đơn vị giao",
        width: 180,
        dataIndex: "tenkkgiao",
      },
      {
        title: "Đơn vị nhận",
        width: 180,
        dataIndex: "tenkknhan",
      },
      {
        title: "Người giao",
        width: 100,
        dataIndex: "nguoigiao",
      },
      {
        title: "Trạng thái",
        width: 80,
        dataIndex: "daduyet",
        align: "center",
        render: (value) => (
          <Tag color={value ? "green" : "red"}>
            {value ? "Đã duyệt" : "Chưa duyệt"}
          </Tag>
        ),
      },
      {
        width: 180,
        fixed: "right",
        align: "center",
        render: (_, record) => (
          <>
            <Button
              title={"Sửa thông tin phiếu"}
              onClick={() => {
                setVisibleModal(!visibleModal);
                setNhapKhoId(record.manx);
              }}
              className="mr-2"
              icon={<EditOutlined />}
              shape="circle"
            />
            <Popconfirm
              title="Bạn muốn xóa phiếu?"
              placement="topLeft"
              onConfirm={() => {
                dispatch(
                  deleteNhapXuat({
                    id: record.manx,
                    onSuccess: () => reloadData(),
                  })
                );
              }}
            >
              <Button
                title={"Xóa phiếu"}
                className="mr-2"
                icon={<DeleteOutlined type="primary" />}
                shape="circle"
              />
            </Popconfirm>
            {/* <Popconfirm
              disabled={!allowLock}
              title={record.daduyet ? "Hủy duyệt nhập kho?" : "Duyệt nhập kho?"}
              placement="topLeft"
              onConfirm={() => {
                dispatch(
                  changeStateNhapXuat({
                    manx: record.manx,
                    daduyet: record.daduyet,
                    sophieu: record.sophieu,
                    makk: record.makk,
                    makkgiao: record.makkgiao,
                    makknhan: record.makknhan,
                    maloaiphieu: record.maloaiphieu,
                    madoituong: record.madoituong,
                    ngay: record.ngay,
                    nguoigiao: record.nguoigiao,
                    nguoinhan: record.nguoinhan,
                    thue: record.thue,
                    mahoadon: record.mahoadon,
                    sohoadon: record.sohoadon,
                    ngayhoadon: record.ngayhoadon,
                    loai: record.loai,
                    manvduyet: record.manvduyet,
                    manv: record.manv,
                    cachtinhthue: record.cachtinhthue,
                    ghichu: record.ghichu,
                    luanchuyen: false,
                    onSuccess: () => reloadData(),
                  })
                );
              }}
            >
              <Button
                disabled={!allowLock}
                title={
                  allowLock
                    ? record.daduyet
                      ? "Hủy duyệt?"
                      : "Duyệt nhập?"
                    : "Bạn không có quyền thao tác"
                }
                className="mr-2"
                icon={
                  !record.daduyet ? (
                    <CheckCircleOutlined type="primary" />
                  ) : (
                    <StopOutlined type="primary" />
                  )
                }
                shape="circle"
              />
            </Popconfirm> */}
          </>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nhapXuatList]
  );

  return (
    <CustomTable
      columns={tableColumns}
      dataSource={nhapXuatList}
      scroll={{ x: "max-content" }}
      rowKey={(item) => item?.id}
    />
  );
}

const CustomTable = styled(Table)`
  .avatar-status:hover {
    text-decoration: underline;
  }
`;
