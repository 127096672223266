import { Button, Popconfirm, Table, Tag } from "antd";
import React, { useMemo } from "react";
import { styled } from "styled-components";
import {
  EditOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
  StopOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import Utils from "utils";
import { permitKey, PermitValue } from "constants";
import {
  changeStateBanHang,
  deleteBanHang,
} from "store/slices/sale/banhangSlice";

export default function TableBanLeList({
  setBanHangId,
  setMaKkxuat,
  setNgayxuat,
  setMadoituong,
  banHangList,
  setVisibleModal,
  reloadData,
  permitList = [],
}) {
  const dispatch = useDispatch();
  const allowLock = Utils.checkPermitValue(
    PermitValue.khoa,
    permitList,
    permitKey.crm_hospital
  );
  const allowEdit = Utils.checkPermitValue(
    PermitValue.sua,
    permitList,
    permitKey.crm_hospital
  );
  const allowDelete = Utils.checkPermitValue(
    PermitValue.xoa,
    permitList,
    permitKey.crm_hospital
  );

  const tableColumns = useMemo(
    () => [
      {
        title: "STT",
        width: 50,
        align: "center",
        render: (_, __, index) => index + 1,
      },
      {
        title: "Số phiếu",
        width: 90,
        dataIndex: "sophieu",
      },
      {
        title: "Ngày",
        width: 120,
        dataIndex: "ngay",
        render: (value) => Utils.formatDate(value),
      },
      {
        title: "Kho xuất",
        width: 160,
        dataIndex: "tenkk",
      },
      {
        title: "Khách hàng",
        width: 180,
        dataIndex: "hoten",
      },
      {
        title: "Trạng thái",
        width: 80,
        dataIndex: "daduyet",
        align: "center",
        render: (value) => (
          <Tag color={value ? "green" : "red"}>
            {value ? "Đã duyệt" : "Chưa duyệt"}
          </Tag>
        ),
      },
      {
        width: 180,
        fixed: "right",
        align: "center",
        render: (_, record) => (
          <>
            <Button
              disabled={!allowEdit}
              title={"Sửa thông tin phiếu"}
              onClick={() => {
                setVisibleModal(true);
                setBanHangId(record.mabanhang);
                setMaKkxuat(record.makk);
                setNgayxuat(record.ngay);
                setMadoituong(record.madoituong);
              }}
              className="mr-2"
              icon={<EditOutlined />}
              shape="circle"
            />
            <Popconfirm
              disabled={!allowDelete}
              title="Bạn muốn xóa phiếu?"
              placement="topLeft"
              onConfirm={() => {
                dispatch(
                  deleteBanHang({
                    id: record.mabanhang,
                    onSuccess: () => reloadData(),
                  })
                );
              }}
            >
              <Button
                disabled={!allowDelete}
                title={
                  allowDelete ? "Xóa phiếu" : "Bạn không có quyền thao tác"
                }
                className="mr-2"
                icon={<DeleteOutlined type="primary" />}
                shape="circle"
              />
            </Popconfirm>
            <Popconfirm
              disabled={!allowLock}
              title={record.daduyet ? "Hủy duyệt?" : "Duyệt phiếu?"}
              placement="topLeft"
              onConfirm={() => {
                dispatch(
                  changeStateBanHang({
                    mabanhang: record.mabanhang,
                    daduyet: record.daduyet,
                    sophieu: record.sophieu,
                    makk: record.makk,
                    hoten: record.hoten,
                    loaiphieu: 0,
                    madoituong: record.madoituong,
                    ngay: record.ngay,
                    masothue: record.masothue,
                    sotaikhoan: record.sotaikhoan,
                    sohoadon: record.sohoadon,
                    manvduyet: record.manvduyet,
                    manv: record.manv,
                    ghichu: record.ghichu,
                    onSuccess: () => reloadData(),
                  })
                );
              }}
            >
              <Button
                disabled={!allowLock}
                title={
                  allowLock
                    ? record.daduyet
                      ? "Hủy duyệt?"
                      : "Duyệt phiếu?"
                    : "Bạn không có quyền thao tác"
                }
                className="mr-2"
                icon={
                  !record.daduyet ? (
                    <CheckCircleOutlined type="primary" />
                  ) : (
                    <StopOutlined type="primary" />
                  )
                }
                shape="circle"
              />
            </Popconfirm>

            <Button
              title={"Trả lại hàng hóa"}
              className="mr-2"
              icon={<UndoOutlined type="primary" />}
              shape="circle"
            />
          </>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [banHangList]
  );

  return (
    <CustomTable
      columns={tableColumns}
      dataSource={banHangList}
      scroll={{ x: "max-content" }}
      rowKey={(item) => item?.id}
    />
  );
}

const CustomTable = styled(Table)`
  .avatar-status:hover {
    text-decoration: underline;
  }
`;
