import axiosInstance from "auth/FetchInterceptor";
import { THIETLAPDMKSK_API, KHAMSUCKHOEDOAN_API } from "constants/ApiConstant";
const KhamSucKhoeDoanService = {};

///THIẾT LẬP
KhamSucKhoeDoanService.GetAllCongTy = function (data) {
  return axiosInstance.post(
    `${THIETLAPDMKSK_API}/thiet-lap-dm-ksk/search-grid-congty`,
    data,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};
KhamSucKhoeDoanService.GetAllCongTyDto = function (data) {
  return axiosInstance.get(
    `${THIETLAPDMKSK_API}/thiet-lap-dm-ksk/get-all-congty`,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};

KhamSucKhoeDoanService.GetCongTy = function (id) {
  return axiosInstance.get(
    `${THIETLAPDMKSK_API}/thiet-lap-dm-ksk/get-congty?id=${id}`,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};

KhamSucKhoeDoanService.UpsertCongTy = function (data) {
  return axiosInstance.put(
    `${THIETLAPDMKSK_API}/thiet-lap-dm-ksk/upsert-congty`,
    data,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};

KhamSucKhoeDoanService.DeleteCongTy = function (data) {
  return axiosInstance.delete(
    `${THIETLAPDMKSK_API}/thiet-lap-dm-ksk/delete-congty?Id=${data}`,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};

KhamSucKhoeDoanService.GetAllDmDichVu = function (data) {
  return axiosInstance.post(
    `${THIETLAPDMKSK_API}/thiet-lap-dm-ksk/search-grid-dm-dichvu`,
    data,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};

KhamSucKhoeDoanService.GetDmDichVu = function (id) {
  return axiosInstance.get(
    `${THIETLAPDMKSK_API}/thiet-lap-dm-ksk/get-dm-dichvu?id=${id}`,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};

KhamSucKhoeDoanService.UpsertDmDichVu = function (data) {
  return axiosInstance.put(
    `${THIETLAPDMKSK_API}/thiet-lap-dm-ksk/upsert-dm-dichvu`,
    data,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};

KhamSucKhoeDoanService.DeleteDmDichVu = function (data) {
  return axiosInstance.delete(
    `${THIETLAPDMKSK_API}/thiet-lap-dm-ksk/delete-dm-dichvu?Id=${data}`,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};

KhamSucKhoeDoanService.GetAllThietLapKskDoan = function (data) {
  return axiosInstance.post(
    `${THIETLAPDMKSK_API}/thiet-lap-dm-ksk/search-grid-thiet-lap-khac`,
    data,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};
KhamSucKhoeDoanService.GetAllThietLapKskDoanDto = function (data) {
  return axiosInstance.get(
    `${THIETLAPDMKSK_API}/thiet-lap-dm-ksk/get-all-thiet-lap-khac?Loai=${data}`,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};

KhamSucKhoeDoanService.GetThietLapKskDoan = function (id) {
  return axiosInstance.get(
    `${THIETLAPDMKSK_API}/thiet-lap-dm-ksk/get-thiet-lap-khac?id=${id}`,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};

KhamSucKhoeDoanService.UpsertThietLapKskDoan = function (data) {
  return axiosInstance.put(
    `${THIETLAPDMKSK_API}/thiet-lap-dm-ksk/upsert-thiet-lap-khac`,
    data,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};

KhamSucKhoeDoanService.DeleteThietLapKskDoan = function (data) {
  return axiosInstance.delete(
    `${THIETLAPDMKSK_API}/thiet-lap-dm-ksk/delete-thiet-lap-khac?Id=${data}`,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};

KhamSucKhoeDoanService.GetAllPhanTramKinhDoanh = function (data) {
  return axiosInstance.post(
    `${THIETLAPDMKSK_API}/thiet-lap-dm-ksk/search-grid-ptkinhdoanh`,
    data,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};
KhamSucKhoeDoanService.GetPhanTramKinhDoanh = function (id) {
  return axiosInstance.get(
    `${THIETLAPDMKSK_API}/thiet-lap-dm-ksk/get-ptkinhdoanh?id=${id}`,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};
KhamSucKhoeDoanService.UpsertPhanTramKinhDoanh = function (data) {
  return axiosInstance.put(
    `${THIETLAPDMKSK_API}/thiet-lap-dm-ksk/upsert-ptkinhdoanh`,
    data,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};
KhamSucKhoeDoanService.DeletePhanTramKinhDoanh = function (data) {
  return axiosInstance.delete(
    `${THIETLAPDMKSK_API}/thiet-lap-dm-ksk/delete-ptkinhdoanh?Id=${data}`,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};
///KSK

KhamSucKhoeDoanService.GetAllDoanKhamSucKhoe = function (data) {
  return axiosInstance.post(
    `${KHAMSUCKHOEDOAN_API}/doan-kham-suc-khoe/search-grid-doan-kham`,
    data,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};
KhamSucKhoeDoanService.UpsertDoanKhamSucKhoe = function (data) {
  return axiosInstance.put(
    `${KHAMSUCKHOEDOAN_API}/doan-kham-suc-khoe/upsert-doan-kham`,
    data,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};
KhamSucKhoeDoanService.GetDoanKhamSucKhoe = function (data) {
  return axiosInstance.get(
    `${KHAMSUCKHOEDOAN_API}/doan-kham-suc-khoe/get-doan-kham?id=${data}`,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};

KhamSucKhoeDoanService.DeleteDoanKhamSucKhoe = function (data) {
  return axiosInstance.delete(
    `${KHAMSUCKHOEDOAN_API}/doan-kham-suc-khoe/delete-doan-kham?id=${data.id}`,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};
//Phuong an kinh doanh
KhamSucKhoeDoanService.GetAllPhuongAnKinhDoanhKsk = function (data) {
  return axiosInstance.post(
    `${KHAMSUCKHOEDOAN_API}/doan-kham-suc-khoe/search-grid-phuong-an-kinh-doanh`,
    data,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};

KhamSucKhoeDoanService.GetPhuongAnKinhDoanh = function (id) {
  return axiosInstance.get(
    `${KHAMSUCKHOEDOAN_API}/doan-kham-suc-khoe/get-phuong-an-kinh-doanh?id=${id}`,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};
KhamSucKhoeDoanService.UpsertPhuongAnKinhDoanh = function (data) {
  return axiosInstance.put(
    `${KHAMSUCKHOEDOAN_API}/doan-kham-suc-khoe/upsert-phuong-an-kinh-doanh`,
    data,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};

KhamSucKhoeDoanService.GetPhuongAnKinhDoanhChiTiet = function (id) {
  return axiosInstance.get(
    `${KHAMSUCKHOEDOAN_API}/doan-kham-suc-khoe/get-phuong-an-kinh-doanh-chi-tiet?PhuongAnId=${id}`,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};
KhamSucKhoeDoanService.UpsertPhuongAnKinhDoanhChiTiet = function (data) {
  return axiosInstance.put(
    `${KHAMSUCKHOEDOAN_API}/doan-kham-suc-khoe/upsert-phuong-an-kinh-doanh-chi-tiet`,
    data,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};
KhamSucKhoeDoanService.UpdatePhuongAnKinhDoanh = function (data) {
  return axiosInstance.get(
    `${KHAMSUCKHOEDOAN_API}/doan-kham-suc-khoe/update-phuong-an-kinh-doanh?PhuongAnId=${data.id}&TrangThai=${data.trangThai}`,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};

KhamSucKhoeDoanService.DeletePhuongAnKinhDoanh = function (data) {
  return axiosInstance.delete(
    `${KHAMSUCKHOEDOAN_API}/doan-kham-suc-khoe/delete-phuong-an-kinh-doanh?id=${data.id}`,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};
KhamSucKhoeDoanService.DeletePhuongAnKinhDoanhChiTiet = function (data) {
  return axiosInstance.delete(
    `${KHAMSUCKHOEDOAN_API}/doan-kham-suc-khoe/delete-phuong-an-kinh-doanh-chi-tiet?id=${data.id}`,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};
//Đơn hàng
KhamSucKhoeDoanService.UpsertDonHangKhamSucKhoe = function (data) {
  return axiosInstance.put(
    `${KHAMSUCKHOEDOAN_API}/doan-kham-suc-khoe/upsert-don-hang-ksk`,
    data,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};
KhamSucKhoeDoanService.UpdateDonHangKhamSucKhoe = function (data) {
  return axiosInstance.get(
    `${KHAMSUCKHOEDOAN_API}/doan-kham-suc-khoe/update-don-hang-ksk?DonHangId=${data.id}&TrangThai=${data.trangThai}`,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};

KhamSucKhoeDoanService.GetDonHangKhamSucKhoe = function (id) {
  return axiosInstance.get(
    `${KHAMSUCKHOEDOAN_API}/doan-kham-suc-khoe/get-don-hang-ksk?DonHangId=${id}`,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};
KhamSucKhoeDoanService.GetAllDonHangKhamSucKhoe = function (data) {
  return axiosInstance.post(
    `${KHAMSUCKHOEDOAN_API}/doan-kham-suc-khoe/search-grid-don-hang-ksk`,
    data,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};
KhamSucKhoeDoanService.DeleteDonHangKhamSucKhoe = function (data) {
  return axiosInstance.delete(
    `${KHAMSUCKHOEDOAN_API}/doan-kham-suc-khoe/delete-don-hang?id=${data.id}`,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};

KhamSucKhoeDoanService.GetAllDonHangKhamSucKhoeKhac = function (data) {
  return axiosInstance.get(
    `${KHAMSUCKHOEDOAN_API}/doan-kham-suc-khoe/get-don-hang-ksk-khac?DonHangId=${data.id}&Loai=${data.loai}`,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};
KhamSucKhoeDoanService.UpsertDonHangKhamSucKhoeKhac = function (data) {
  return axiosInstance.put(
    `${KHAMSUCKHOEDOAN_API}/doan-kham-suc-khoe/upsert-don-hang-ksk-khac`,
    data,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};
KhamSucKhoeDoanService.DeleteDonHangKhamSucKhoeKhac = function (data) {
  return axiosInstance.delete(
    `${KHAMSUCKHOEDOAN_API}/doan-kham-suc-khoe/delete-don-hang-khac?id=${data.id}`,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};
//THanh lý hợp đồng
KhamSucKhoeDoanService.GetAllThanhLyHopDongKsk = function (data) {
  return axiosInstance.post(
    `${KHAMSUCKHOEDOAN_API}/doan-kham-suc-khoe/search-grid-thanh-ly-hop-dong`,
    data,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};
KhamSucKhoeDoanService.GetThanhLyHopDongKsk = function (id) {
  return axiosInstance.get(
    `${KHAMSUCKHOEDOAN_API}/doan-kham-suc-khoe/get-thanh-ly-hop-dong?ThanhLyId=${id}`,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};
KhamSucKhoeDoanService.UpsertThanhLyHopDongKsk = function (data) {
  return axiosInstance.put(
    `${KHAMSUCKHOEDOAN_API}/doan-kham-suc-khoe/upsert-thanh-ly-hop-dong`,
    data,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};
KhamSucKhoeDoanService.GetThanhLyHopDongChiTiet = function (data) {
  return axiosInstance.get(
    `${KHAMSUCKHOEDOAN_API}/doan-kham-suc-khoe/get-thanh-ly-hop-dong-chi-tiet?ThanhLyId=${data}`,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};
KhamSucKhoeDoanService.DeleteThanhLyHopDongKsk = function (data) {
  return axiosInstance.delete(
    `${KHAMSUCKHOEDOAN_API}/doan-kham-suc-khoe/delete-thanh-ly?id=${data.id}`,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};
KhamSucKhoeDoanService.GetThanhLyHopDongChiTietKhac = function (data) {
  return axiosInstance.get(
    `${KHAMSUCKHOEDOAN_API}/doan-kham-suc-khoe/get-thanh-ly-chi-tiet-khac?ThanhLyId=${data}`,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};
KhamSucKhoeDoanService.UpsertThanhLyHopDongKskChiTietKhac = function (data) {
  return axiosInstance.post(
    `${KHAMSUCKHOEDOAN_API}/doan-kham-suc-khoe/upsert-thanh-ly-chi-tiet-khac`,
    data,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};
KhamSucKhoeDoanService.DeleteThanhLyHopDongKskChiTietKhac = function (data) {
  return axiosInstance.delete(
    `${KHAMSUCKHOEDOAN_API}/doan-kham-suc-khoe/delete-thanh-ly-chi-tiet-khac?Id=${data.id}`,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};

///Thu tiền
KhamSucKhoeDoanService.GetAllThuTien = function (data) {
  return axiosInstance.post(
    `${KHAMSUCKHOEDOAN_API}/doan-kham-suc-khoe/search-grid-thu-tien`,
    data,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};
KhamSucKhoeDoanService.GetThuTien = function (id) {
  return axiosInstance.get(
    `${KHAMSUCKHOEDOAN_API}/doan-kham-suc-khoe/get-thu-tien?id=${id}`,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};
KhamSucKhoeDoanService.UpsertThuTien = function (data) {
  return axiosInstance.put(
    `${KHAMSUCKHOEDOAN_API}/doan-kham-suc-khoe/upsert-thu-tien`,
    data,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};
KhamSucKhoeDoanService.DeleteThuTien = function (data) {
  return axiosInstance.delete(
    `${KHAMSUCKHOEDOAN_API}/doan-kham-suc-khoe/delete-thu-tien?id=${data.id}`,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};

//Báo cáo công nợ
KhamSucKhoeDoanService.GetBaoCaoCongNo = function (data) {
  return axiosInstance.post(
    `${KHAMSUCKHOEDOAN_API}/doan-kham-suc-khoe/bao-cao-cong-no`,
    data,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};
KhamSucKhoeDoanService.GetBaoCaoDoanhThu = function (data) {
  return axiosInstance.post(
    `${KHAMSUCKHOEDOAN_API}/doan-kham-suc-khoe/bao-cao-doanh-thu`,
    data,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};
KhamSucKhoeDoanService.ExportBaoCao = function (data) {
  return axiosInstance.post(
    `${KHAMSUCKHOEDOAN_API}/doan-kham-suc-khoe/bao-cao-export-excel`,
    data,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};

export default KhamSucKhoeDoanService;
