import { Card, Spin } from "antd";
import FilterBar from "components/searchBar/FilterBar";
import React, { useEffect, useState } from "react";
import TableDmnhomthuocList from "./TableDmnhomthuocList";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import Utils from "utils";
import { getAllDmnhomthuoc } from "store/slices/sale/danhmucSlice";
import { PermitValue, permitKey } from "constants";
import moment from "moment";
const initSearch = {
  startDate: moment().date(-30), //.startOf("month"),
  endDate: moment().endOf("month"),
  searchText: null,
};
export const initDmnhomthuoc = {
  action: "initial",
  isRequired: true,
};
const DmnhomthuocList = () => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState(initSearch);
  const { permitList } = useSelector((state) => state.auth);
  const { allDmnhomthuocList, loading } = useSelector(
    (state) => state.danhMucSale
  );
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dispatch(getAllDmnhomthuoc(filter));
  }, [dispatch, filter]);

  const reloadData = (filterData = filter) => {
    dispatch(getAllDmnhomthuoc(filter));
    dispatch(filterData);
  };

  const allowAddNew = Utils.checkPermitValue(
    PermitValue.them,
    permitList,
    permitKey.crm_hospital
  );

  return (
    <>
      <CustomDiv className="header d-flex justify-content-between">
        <CustomH3
          style={{ textTransform: "uppercase" }}
          className="title "
          mb={{ xs: "s6" }}
        >
          Danh mục nhóm thuốc
        </CustomH3>
      </CustomDiv>
      <Card>
        <Spin tip="Đang tải..." spinning={loading}>
          <FilterBar
            filter={filter}
            setFilter={setFilter}
            showButton={false}
            showDate={false}
          />
          <TableDmnhomthuocList
            allDmnhomthuocList={allDmnhomthuocList}
            reloadData={() => reloadData()}
            permitList={permitList}
          />
        </Spin>
      </Card>
    </>
  );
};

export default DmnhomthuocList;

const CustomDiv = styled.div``;
const CustomH3 = styled.h3`
  font-weight: 700;
`;
