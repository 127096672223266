import React, { useEffect, useRef, useState } from "react";
import TableXuatKhoList from "./TableXuatKhoList";
import ModalXuatKho from "./ModalXuatKho";
import { useDispatch, useSelector } from "react-redux";
import {
  getDSNhapXuat,
  getNhapXuatByManx,
} from "store/slices/sale/nhapxuatSlice";
import { getXuatKhoCTByManx } from "store/slices/sale/xuatkhoSlice";
import MeditechTablePage, { FilterComponent } from "components/table-layout";
import { SystemButtonSource } from "constants";
const initSearch = {
  startDate: null, // moment().date(-60),
  endDate: null,
  searchText: null,
  loai: -1,
  luanchuyen: false,
};
export const initXuatKhoCt = {
  action: "initial",
  isRequired: true,
};
const XuatKhoList = () => {
  const dispatch = useDispatch();
  const [visibleModal, setVisibleModal] = useState(false);
  const { permitList } = useSelector((state) => state.auth);
  const [xuatKhoId, setXuatKhoId] = useState(null);
  const [maKkxuat, setMaKkxuat] = useState(null);
  const [ngayxuat, setNgayxuat] = useState(null);
  const searchFormRef = useRef({ ...initSearch });
  const [searchFormData, setSearchFormData] = useState(searchFormRef.current);
  const { nhapXuatList, loading, nhapXuatDetail } = useSelector(
    (state) => state.nhapXuatSale
  );
  const { xuatKhoCTList } = useSelector((state) => state.xuatKhoSale);
  useEffect(() => {
    reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (xuatKhoId === null) {
      return;
    }
    dispatch(getNhapXuatByManx(xuatKhoId));

    dispatch(
      getXuatKhoCTByManx({
        manx: xuatKhoId,
        isNewCt: false,
      })
    );
  }, [dispatch, xuatKhoId]);

  const reloadData = () => {
    dispatch(getDSNhapXuat(searchFormRef.current));
  };

  const searchHandle = (dataSearch) => {
    searchFormRef.current = { ...searchFormRef.current, ...dataSearch };
    setSearchFormData({ ...searchFormRef.current });
    reloadData();
  };
  const onRenderSearchView = () => {
    return (
      <FilterComponent
        datasource={searchFormData}
        onSearchImmediate={searchHandle}
        renderInputSearch
        renderDatePicker
      >
        {/* <Select
          key="trangThai"
          data-field="trangThai"
          style={{ width: "100%", minWidth: 200 }}
          placeholder="Trạng thái"
          options={listTrangThai}
          allowClear
          mode="multiple"
        /> */}
      </FilterComponent>
    );
  };

  const onDeleteHandler = (id) => {};

  const onOpenDetailHandler = (id) => {
    //setDoanKhamId(id);
    setVisibleModal(true);
  };

  const getRightButton = (isLocked) => {
    const arr = [];
    if (isLocked) {
      arr.push({
        keyName: SystemButtonSource.addBtn,
        title: "Thêm",
        showMore: false,
        // disable: !allowAddNew,
      });
    }
    return arr;
  };

  const onRightTopBtnHandler = (key) => {
    switch (key) {
      case SystemButtonSource.addBtn:
        setVisibleModal((prev) => !prev);
        break;
      default:
        break;
    }
  };
  return (
    <>
      <MeditechTablePage
        tableTitle="Quản lý xuất kho"
        btnHeaderRightSource={getRightButton(true)}
        btnHeaderRightSourceHandle={onRightTopBtnHandler}
        onRenderSearchView={onRenderSearchView}
      >
        <TableXuatKhoList
          loading={loading}
          setXuatKhoId={setXuatKhoId}
          nhapXuatList={nhapXuatList}
          reloadData={() => reloadData()}
          setVisibleModal={setVisibleModal}
          visibleModal={visibleModal}
          permitList={permitList}
          setMaKkxuat={setMaKkxuat}
          setNgayxuat={setNgayxuat}
          onOpenDetail={onOpenDetailHandler}
          onDelete={onDeleteHandler}
        />
      </MeditechTablePage>
      <ModalXuatKho
        visibleModal={visibleModal}
        loading={false}
        setVisibleModal={setVisibleModal}
        setXuatKhoId={setXuatKhoId}
        xuatKhoId={xuatKhoId}
        nhapXuatDetail={nhapXuatDetail}
        reloadData={() => reloadData()}
        permitList={permitList}
        xuatKhoCTList={xuatKhoCTList}
        setMaKkxuat={setMaKkxuat}
        maKkxuat={maKkxuat}
        setNgayxuat={setNgayxuat}
        ngayxuat={ngayxuat}
      />
    </>
  );
};

export default XuatKhoList;
