import {
  Col,
  Form,
  Input,
  Row,
  Select,
  Spin,
  DatePicker,
  Button,
  InputNumber,
} from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TableBanLeCTList from "./TableBanLeCTList";

import { createBanHang, updateBanHang } from "store/slices/sale/banhangSlice";

import {
  getDmDoiTuong,
  getDmKk,
  getDmThuocVatTu,
  getDmPhai,
} from "store/slices/sale/danhmucSlice";

import Utils from "utils";

import dayjs from "dayjs";
import SlipModalComponent from "components/SlipModalComponent";
import { isEmpty } from "lodash";
// Filter `option.label` match the user type `input`
const filterOption = (input, option) =>
  (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
const formater = new Intl.NumberFormat("vi-VN");
// formatter and parser input number
export const formatterNumber = (val) => {
  if (!val) return 0;
  return formater.format(val);
};

export const parserNumber = (val) => {
  if (!val) return 0;
  return Number.parseFloat(
    val.replace(/\$\s?|(\.*)/g, "").replace(/(,{1})/g, ".")
  ).toFixed(5);
};
export default function ModalBanLe({
  visibleModal,
  setVisibleModal,
  loading,
  banHangDetail,
  setBanHangId,
  banHangId,
  reloadData,
  banHangCTList = [],
  permitList = [],
  setMaKkxuat,
  maKkxuat,
  setNgayxuat,
  ngayxuat,
  setMadoituong,
  madoituong,
  getBanHangCTByMabanhang,
}) {
  const dispatch = useDispatch();
  const { dmKkList, dmDoiTuongList, dmThuocVatTuList, dmPhaiList } =
    useSelector((state) => state.danhMucSale);
  useEffect(() => {
    dispatch(getDmDoiTuong());
    dispatch(getDmKk());
    dispatch(getDmThuocVatTu());
    dispatch(getDmPhai());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  const [form] = Form.useForm();

  useEffect(() => {
    if (banHangId === null) {
      dispatch(getBanHangCTByMabanhang({ mabanhang: -1, isNewCt: false }));
      form.setFieldValue("ngay", dayjs());
      return;
    }
    form.setFieldsValue(banHangDetail);
    form.setFieldValue("ngay", dayjs(banHangDetail.ngay));
  }, [form, banHangDetail, banHangId, dispatch, getBanHangCTByMabanhang]);

  const onFinish = (values) => {
    const payload = {
      sophieu: values.sophieu,
      makk: values.makk,
      loaiphieu: 0,
      madoituong: values.madoituong,
      ngay: values.ngay,
      maphai: values.maphai,
      hoten: values.hoten,
      masothue: values.masothue,
      sotaikhoan: values.sotaikhoan,
      daduyet: values.daduyet,
      manvduyet: values.manvduyet,
      manv: values.manv,
      ghichu: values.ghichu,
      dienthoai: values.dienthoai,
      diachi: values.diachi,
      onSuccess: ({ data }) => {
        if (data !== null) {
          setBanHangId(data);
        } else {
          setBanHangId(null);
        }
        setMaKkxuat(values.makk);
        setNgayxuat(values.ngay);
        setMadoituong(values.madoituong);
        reloadData();
      },
    };

    if (banHangId !== null) {
      payload.mabanhang = banHangId;
      dispatch(updateBanHang(payload));
    } else {
      dispatch(createBanHang(payload));
    }
  };
  const renderInfo = () => {
    return (
      <Spin tip="Đang tải..." spinning={loading}>
        <Form form={form} layout="vertical" onFinish={onFinish} className="p-3">
          <Row gutter={24}>
            <Col sm={8} md={8} lg={8}>
              <Form.Item name="sophieu" label="Số phiếu">
                <Input placeholder="Tự động" readOnly />
              </Form.Item>
            </Col>{" "}
            <Col sm={8} md={8} lg={8}>
              <Form.Item
                name="ngay"
                label="Ngày bán"
                rules={[
                  {
                    required: true,
                    message: "Ngày lập phiếu là bắt buộc",
                  },
                ]}
              >
                <DatePicker
                  showTime
                  style={{ width: "100%" }}
                  placeholder={"Ngày bán"}
                />
              </Form.Item>
            </Col>
            <Col sm={8} md={8} lg={8}>
              <Form.Item
                name="madoituong"
                label="Đối tượng"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng chọn đối tượng",
                  },
                ]}
              >
                <Select
                  showSearch
                  filterOption={filterOption}
                  options={dmDoiTuongList}
                  placeholder="Chọn đối tượng"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col sm={24} md={24} lg={24}>
              <Form.Item
                name="makk"
                label="Kho xuất"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng chọn kho xuất",
                  },
                ]}
              >
                <Select
                  showSearch
                  filterOption={filterOption}
                  options={dmKkList?.filter((x) => x.maloai === 1)}
                  placeholder="Chọn kho xuất"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col sm={16} md={16} lg={16}>
              <Form.Item
                name="hoten"
                label="Khách hàng"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập tên khách hàng",
                  },
                ]}
              >
                <Input
                  placeholder="Nhập tên khách hàng"
                  onInput={(e) =>
                    (e.target.value = e.target.value.toUpperCase())
                  }
                />
              </Form.Item>
            </Col>
            <Col sm={8} md={8} lg={8}>
              <Form.Item
                name="dienthoai"
                label="SĐT"
                rules={[Utils.validatePhoneNumberVN]}
              >
                <Input placeholder="Nhập số điện thoại" allowClear={true} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col sm={8} md={8} lg={8}>
              <Form.Item name="ngaysinh" label="Ngày sinh">
                <DatePicker
                  showTime
                  style={{ width: "100%" }}
                  placeholder={"Ngày sinh"}
                />
              </Form.Item>
            </Col>
            <Col sm={8} md={8} lg={8}>
              <Form.Item name="maphai" label="Giới tính">
                <Select
                  showSearch
                  filterOption={filterOption}
                  options={dmPhaiList}
                  placeholder="Chọn giới tính"
                />
              </Form.Item>
            </Col>
            <Col sm={8} md={8} lg={8}>
              <Form.Item name="diachi" label="Địa chỉ">
                <Input placeholder="Nhập địa chỉ" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col sm={24} md={24} lg={24}>
              <Form.Item name="ghichu" label="Ghi chú">
                <Input placeholder="Nhập ghi chú" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col sm={8} md={8} lg={8}>
              <Form.Item name="congno" label="Công nợ">
                <InputNumber
                  style={{ width: "100%" }}
                  step={1000}
                  formatter={(value) => formatterNumber(value)}
                  parser={(value) => parserNumber(value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col sm={24}>
              <Button
                type="primary"
                onClick={() => {
                  form.submit();
                }}
              >
                Lưu
              </Button>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col sm={24} md={24} lg={0}>
              <Form.Item name="daduyet" label="Đã duyệt" hidden>
                <Input></Input>
              </Form.Item>
            </Col>
            <Col sm={24} md={24} lg={0}>
              <Form.Item name="manvduyet" label="Mã nhân viên duyệt" hidden>
                <Input></Input>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    );
  };
  return (
    <SlipModalComponent
      maskClosable={false}
      bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 300px)" }}
      width={1400}
      title={!isEmpty(banHangId) ? "Thêm phiếu bán lẻ" : "Sửa phiếu bán lẻ"}
      objectId={banHangId}
      objectType={"PhieuBanLe"}
      //documentTypeId={DOCUMENT_TYPE_ID.DanhGiaUngVien}
      widthInfo={550}
      //titleTLink="Đoàn khám sức khỏe"
      //descriptionTLink={DoanKhamSucKhoeDetail?.maUngVien}
      isVisibleModal={visibleModal}
      onRenderTab={[
        {
          label: "Thông tin chi tiêt",
          children: (
            <TableBanLeCTList
              setBanHangId={setBanHangId}
              banHangCTList={banHangCTList}
              dmThuocVatTuList={dmThuocVatTuList}
              permitList={permitList}
              banHangId={banHangId}
              maKkxuat={maKkxuat}
              ngayxuat={ngayxuat}
              madoituong={madoituong}
            />
          ),
        },
      ]}
      onRenderInfo={() => renderInfo()}
      onCancelModal={() => {
        setVisibleModal(!visibleModal);
        form.resetFields();
        if (!isEmpty(banHangId)) setBanHangId(null);
      }}
    />
  );
}
