import React from "react";
import {
  Input,
  InputNumber,
  Checkbox,
  Form,
  Switch,
  DatePicker,
  Select,
} from "antd";
import { dateFormatList } from "constants";
import { dateTimeFormatList } from "constants";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  isRequired = false,
  ...restProps
}) => {
  const inputNode =
    inputType === "number" ? (
      <InputNumber placeholder={title} min={0} />
    ) : inputType === "checkbox" ? (
      <Checkbox />
    ) : inputType === "switch" ? (
      <Switch />
    ) : inputType === "select" ? (
      <Select
        placeholder={title}
        value={record.groupId}
        options={restProps?.options || []}
        allowClear
        onChange={(value) => restProps?.onChange && restProps.onChange(value)}
      />
    ) : inputType === "date" ? (
      <DatePicker placeholder={title} format={dateFormatList} />
    ) : inputType === "datetime" ? (
      <DatePicker
        placeholder={title}
        format={dateTimeFormatList}
        showTime={{ format: "HH:mm" }}
      />
    ) : (
      <Input placeholder={title} />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          valuePropName={
            ["checkbox", "switch"].includes(inputType) ? "checked" : "value"
          }
          rules={[
            {
              required: isRequired,
              message: `bắt buộc`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default EditableCell;
