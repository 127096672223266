import {
  SIDE_NAV_LIGHT,
  NAV_TYPE_SIDE,
  DIR_LTR,
} from "constants/ThemeConstant";
import { env } from "./EnvironmentConfig";

export const APP_NAME = "Hệ thống Y Tế Medic";
export const API_BASE_URL = env.API_ENDPOINT_URL;
export const APP_PREFIX_PATH = "/app";
export const AUTH_PREFIX_PATH = "/auth";
export const REDIRECT_URL_KEY = "redirect";
export const AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/dashboards/default`;
export const UNAUTHENTICATED_ENTRY = "/login";
export const CDHA_PREFIX_PATH = "/crm";

export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: "vi",
  navType: NAV_TYPE_SIDE,
  topNavColor: "#3e82f7",
  headerNavColor: "",
  mobileNav: false,
  currentTheme: "light",
  direction: DIR_LTR,
  blankLayout: false,
};

export const SYSTEM_SCOPE_ID = {
  Hr: 1, // Nhan su
  Crm: 2, // Khac hang
  His: 3, // HIS
  Identity: 4, // Phan quyen - common
  Project: 5, // cong van - cong viec
  Asset: 6, // tai san
  Finance: 7, // Tai chinh - ke toan
  Report: 8, // Bao cao quan tri
};
export const SYSTEM_SCOPE = [
  {
    value: SYSTEM_SCOPE_ID.Hr,
    label: "Nhân sự",
    icon: null,
    disable: false,
    path: "/app/hrm",
  },
  {
    value: SYSTEM_SCOPE_ID.Crm,
    label: "Khách hàng",
    icon: null,
    disable: false,
    path: "/app/crm",
  },
  {
    value: SYSTEM_SCOPE_ID.Project,
    label: "Công việc",
    icon: null,
    disable: false,
    path: "/app/project",
  },
  {
    value: SYSTEM_SCOPE_ID.Asset,
    label: "Tài sản",
    icon: null,
    disable: true,
    path: "/app/asset",
  },
  {
    value: SYSTEM_SCOPE_ID.Finance,
    label: "TC-KT",
    icon: null,
    disable: true,
    path: "/app/finance",
  },
  {
    value: SYSTEM_SCOPE_ID.Identity,
    label: "Thiết lập",
    icon: null,
    disable: false,
    path: "/app/common",
  },
];
export const DETAIL_PAGE_PATH = `${APP_PREFIX_PATH}/common/detail-page`;
export const SIGN_REPORT_PATH = `${APP_PREFIX_PATH}/common/tong-hop-ky`;
export const NOTIFICATION_REPORT_PATH = `${APP_PREFIX_PATH}/common/tong-hop-thong-bao`;
