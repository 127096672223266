import { Modal, Select, Spin, Tag } from "antd";
import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllEmployeeSignProcess } from "store/slices/employeeSlice";
import { isEmpty } from "lodash";
import Utils from "utils";
import { GENDER } from "constants";
import {
  getByWorkStatusAPI,
  getDepartmentByBranch,
} from "store/slices/categorySlice";
import { PersonnelForm, HoSoDeTenArr } from "constants";
import MeditechTablePage, { FilterComponent } from "components/table-layout";
import MediTable from "components/Custom";

const initEmployee = {
  searchText: null,
  branchId: null,
  workStatus: null,
  gender: null,
  departmentById: [],
  isCertificate: null,
  type: 1,
  hoSoDeTen: null,
  isSearchModal: true,
  employeeIds: [],
};

const lstCertificate = [
  {
    value: 1,
    label: "Đã có CCHN",
  },
  {
    value: 0,
    label: "Chưa có CCHN",
  },
];

const ModalEmployeeSignProcess = ({
  visibleModal,
  onCancel,
  isBangCong = false,
  selectedRowKeys,
  setSelectedRowKeys,
  onOk,
  footer,
  isDeptByBranch = false,
  branchId = null,
  hoSoDeTen = null, // loc theo hinh thuc nhan su
  type = "checkbox",
  employeeIds,
}) => {
  const dispatch = useDispatch();
  const searchFormRef = useRef({
    ...initEmployee,
    branchId,
    hoSoDeTen,
    isSearchModal: !isEmpty(branchId),
  });
  const [searchFormData, setSearchFormData] = useState(searchFormRef.current);
  const { profile } = useSelector((state) => state.auth);
  const { employeeSignProcessList, loading } = useSelector(
    (state) => state.employee
  );
  const { deptByBranchList, workStatusList } = useSelector(
    (state) => state.category
  );
  const [branchInfoList, setBranchInfoList] = useState([]);

  useEffect(() => {
    const arrBranch = profile?.branchInfos
      ?.filter((i) => i.allowAccess)
      ?.map((x) => ({
        label: x.branchName,
        value: x.branchId,
      }));
    setBranchInfoList(arrBranch || []);
  }, [dispatch, profile?.branchInfos]);

  useEffect(() => {
    dispatch(getByWorkStatusAPI());
  }, [dispatch]);

  useEffect(() => {
    searchFormRef.current = {
      ...searchFormRef.current,
      branchId,
      hoSoDeTen,
      employeeIds,
    };
    setSearchFormData(searchFormRef.current);
    dispatch(getAllEmployeeSignProcess(searchFormRef.current));
  }, [dispatch, branchId, hoSoDeTen, employeeIds]);

  useEffect(() => {
    if (searchFormData && !searchFormData.branchId) return;
    dispatch(getDepartmentByBranch(searchFormData.branchId));
  }, [dispatch, searchFormData, searchFormData.branchId]);

  const tableColumns = useMemo(
    () => [
      {
        title: "Mã NV",
        align: "center",
        dataIndex: "code",
        fixed: "left",
      },
      {
        title: "Tên nhân viên",
        dataIndex: "fullName",
        fixed: "left",
      },
      {
        title: "Trực thuộc",
        align: "center",
        dataIndex: "branchName",
      },
      {
        title: "Phòng ban",
        align: "center",
        dataIndex: "departmentName",
      },
      {
        title: "Email",
        dataIndex: "email",
      },
      {
        title: "Số điện thoại",
        align: "center",
        dataIndex: "phone",
      },
      {
        title: "Chứng chỉ nghề",
        align: "center",
        dataIndex: "certificate",
        render: (text) => (!isEmpty(text) ? text : "Chưa cập nhật"),
      },
      {
        title: "Ngày cấp CCHN",
        align: "center",
        dataIndex: "certificateIssueDate",
        render: (date) =>
          !isEmpty(date) ? Utils.formatDate(date, true) : "Chưa cập nhật",
      },
      {
        title: "Hình thức NS",
        align: "center",
        dataIndex: "typeName",
        render: (value) => PersonnelForm.find((x) => x.value === value)?.label,
      },
      {
        title: "Ngày sinh",
        align: "center",
        dataIndex: "birthday",
        render: (date) => date && Utils.formatDate(date, true),
      },
      {
        title: "Giới tính",
        dataIndex: "gender",
        sorter: (a, b) => a.gender - b.gender,
        align: "center",
        render: (value) => GENDER.find((i) => i.value === value)?.label,
      },
      {
        title: "Mã HIS",
        align: "center",
        dataIndex: "oldCode",
      },
      {
        title: "Trạng thái làm việc",
        align: "center",
        dataIndex: "workStatusName",
      },
      {
        title: "Ngày làm việc",
        align: "center",
        dataIndex: "workingStartDate",
        render: (text) =>
          !isEmpty(text) ? Utils.formatDate(text, true) : "Chưa cập nhật",
      },
      {
        title: "Ngày nghỉ việc",
        align: "center",
        dataIndex: "workingEndDate",
        render: (text) =>
          !isEmpty(text) ? Utils.formatDate(text, true) : "Chưa cập nhật",
      },
      {
        title: "Quê quán",
        width: 200,
        dataIndex: "address",
        render: (text) => (!isEmpty(text) ? text : "Chưa cập nhật"),
      },

      {
        title: "Trạng thái",
        dataIndex: "isActive",
        fixed: "right",
        align: "center",
        render: (isActive) => (
          <Tag color={isActive ? "green" : "red"}>
            {isActive ? "Hoạt động" : "Khóa tài khoản"}
          </Tag>
        ),
      },
    ],
    []
  );

  const searchHandle = (dataSearch) => {
    searchFormRef.current = { ...searchFormRef.current, ...dataSearch };
    setSearchFormData({ ...searchFormRef.current });
    dispatch(getAllEmployeeSignProcess(searchFormRef.current));
  };

  const onRenderSearchView = () => {
    return (
      <FilterComponent
        datasource={searchFormData}
        onSearchImmediate={searchHandle}
        renderInputSearch
      >
        {!isBangCong && (
          <Select
            key="branchId"
            data-field="branchId"
            style={{ width: "100%", minWidth: 200 }}
            placeholder="Chi nhánh"
            options={branchInfoList}
            allowClear
          />
        )}
        {isDeptByBranch && (
          <Select
            key="departmentById"
            data-field="departmentById"
            style={{ width: "100%", minWidth: 200 }}
            placeholder="Phòng ban/Trực thuộc"
            options={deptByBranchList}
            mode="multiple"
            allowClear
          />
        )}
        <Select
          key="isCertificate"
          data-field="isCertificate"
          style={{ width: "100%", minWidth: 200 }}
          placeholder="Chứng chỉ nghề"
          options={lstCertificate}
          allowClear
        />
        <Select
          key="hoSoDeTen"
          data-field="hoSoDeTen"
          style={{ width: "100%", minWidth: 200 }}
          placeholder="Hồ sơ để tên"
          options={HoSoDeTenArr}
          allowClear
        />
        {/* {isDeptByBranch && (
          <Select
            key="type"
            data-field="type"
            style={{ width: "100%", minWidth: 200 }}
            placeholder="Hình thức nhân sự"
            options={PersonnelForm}
            // allowClear
          />
        )} */}
        <Select
          key="workStatus"
          data-field="workStatus"
          style={{ width: "100%", minWidth: 200 }}
          placeholder="Trạng thái làm việc"
          options={workStatusList}
          allowClear
        />
      </FilterComponent>
    );
  };

  return (
    <>
      <Modal
        open={visibleModal}
        maskClosable={false}
        bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 180px)" }}
        width={1400}
        onCancel={onCancel}
        onOk={onOk}
        okText="Lưu"
        footer={footer}
      >
        <Spin tip="Đang tải..." spinning={loading}>
          <MeditechTablePage
            tableTitle="Nhân viên"
            onRenderSearchView={onRenderSearchView}
          >
            <MediTable
              dataSource={employeeSignProcessList}
              tableColumns={
                isBangCong
                  ? tableColumns.filter((x) => !(x.name === "action"))
                  : tableColumns
              }
              enableSelectAll
              onRowSelectChange={setSelectedRowKeys}
              rowSelectionType={type}
              rowKeyValue={selectedRowKeys}
            />
          </MeditechTablePage>
        </Spin>
      </Modal>
    </>
  );
};

export default memo(ModalEmployeeSignProcess);
