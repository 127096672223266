import axiosInstance from "auth/FetchInterceptor";
import { KHACH_HANG_API } from "constants/ApiConstant";
const KhachHangService = {};

KhachHangService.getDSKhachHang = function (data) {
  return axiosInstance.post(`${KHACH_HANG_API}/khach-hang`, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

KhachHangService.getAllKhaiBaoNhom = function (data) {
  return axiosInstance.post(`${KHACH_HANG_API}/get-all-nhom`, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

KhachHangService.getAllBenhICD10 = function () {
  return axiosInstance.get(`${KHACH_HANG_API}/get-all-icd10`, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

KhachHangService.getDSNhom = function () {
  return axiosInstance.get(`${KHACH_HANG_API}/get-ds-nhom`, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};
KhachHangService.addNewKhaiBaoNhom = function (data) {
  return axiosInstance.post(`${KHACH_HANG_API}/add-new-khaibaonhom`, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};
KhachHangService.editKhaiBaoNhom = function (data) {
  return axiosInstance.post(`${KHACH_HANG_API}/edit-khaibaonhom`, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};
KhachHangService.dongBoKhachHangVaoNhom = function (data) {
  return axiosInstance.post(`${KHACH_HANG_API}/dongbo-khachhang-nhom`, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};
KhachHangService.addBenhNhanNhom = function (data) {
  return axiosInstance.post(`${KHACH_HANG_API}/add-benhnhan-nhom`, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};
KhachHangService.getLichSuKcbByIdBenhNhan = function (id) {
  return axiosInstance.get(
    `${KHACH_HANG_API}/lich-su-kcb-khach-hang?idBenhNhan=${id}`,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};
KhachHangService.getLichHenKcbByIdBenhNhan = function (id) {
  return axiosInstance.get(
    `${KHACH_HANG_API}/lich-hen-kcb-khach-hang?idBenhNhan=${id}`,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};

export default KhachHangService;
