import React, { useEffect, useRef, useState } from "react";
import TableBanLeList from "./TableBanLeList";
import ModalBanLe from "./ModalBanLe";
import { useDispatch, useSelector } from "react-redux";
import {
  getDSBanHang,
  getBanHangByMabanhang,
} from "store/slices/sale/banhangSlice";
import { getBanHangCTByMabanhang } from "store/slices/sale/banhangSlice";
import moment from "moment";
import { SystemButtonSource } from "constants";
import MeditechTablePage, { FilterComponent } from "components/table-layout";
const initSearch = {
  startDate: moment().startOf("month"), // moment().date(-60),
  endDate: moment().endOf("month"),
  searchText: null,
  loai: 0,
};
export const initBanHangCt = {
  action: "initial",
  isRequired: true,
};
const BanLeList = () => {
  const dispatch = useDispatch();
  const [visibleModal, setVisibleModal] = useState(false);
  const { permitList } = useSelector((state) => state.auth);
  const [banHangId, setBanHangId] = useState(null);
  const [maKkxuat, setMaKkxuat] = useState(null);
  const [ngayxuat, setNgayxuat] = useState(null);
  const [madoituong, setMadoituong] = useState(null);
  const searchFormRef = useRef({ ...initSearch });
  const [searchFormData, setSearchFormData] = useState(searchFormRef.current);
  const { banHangList, loading, banHangDetail } = useSelector(
    (state) => state.banHangSale
  );
  const { branchId } = useSelector((state) => state.auth);
  const { banHangCTList } = useSelector((state) => state.banHangSale);
  useEffect(() => {
    reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, branchId]);

  useEffect(() => {
    if (banHangId === null) {
      return;
    }
    dispatch(getBanHangByMabanhang({ id: banHangId, branchId }));
    dispatch(
      getBanHangCTByMabanhang({
        mabanhang: banHangId,
        isNewCt: false,
        branchId,
      })
    );
  }, [dispatch, banHangId, branchId]);

  const reloadData = () => {
    dispatch(getDSBanHang(searchFormRef.current));
  };
  const searchHandle = (dataSearch) => {
    searchFormRef.current = { ...searchFormRef.current, ...dataSearch };
    setSearchFormData({ ...searchFormRef.current });
    reloadData();
  };
  const onRenderSearchView = () => {
    return (
      <FilterComponent
        datasource={searchFormData}
        onSearchImmediate={searchHandle}
        renderInputSearch
        renderDatePicker
      >
        {/* <Select
          key="trangThai"
          data-field="trangThai"
          style={{ width: "100%", minWidth: 200 }}
          placeholder="Trạng thái"
          options={listTrangThai}
          allowClear
          mode="multiple"
        /> */}
      </FilterComponent>
    );
  };

  const onDeleteHandler = (id) => {};

  const onOpenDetailHandler = (id) => {
    //setDoanKhamId(id);
    setVisibleModal(true);
  };

  const getRightButton = (isLocked) => {
    const arr = [];
    if (isLocked) {
      arr.push({
        keyName: SystemButtonSource.addBtn,
        title: "Thêm",
        showMore: false,
        // disable: !allowAddNew,
      });
    }
    return arr;
  };

  const onRightTopBtnHandler = (key) => {
    switch (key) {
      case SystemButtonSource.addBtn:
        setVisibleModal((prev) => !prev);
        break;
      default:
        break;
    }
  };
  return (
    <>
      <MeditechTablePage
        tableTitle="Bán lẻ"
        btnHeaderRightSource={getRightButton(true)}
        btnHeaderRightSourceHandle={onRightTopBtnHandler}
        onRenderSearchView={onRenderSearchView}
      >
        <TableBanLeList
          loading={loading}
          setBanHangId={setBanHangId}
          banHangList={banHangList}
          setVisibleModal={setVisibleModal}
          visibleModal={visibleModal}
          permitList={permitList}
          setMaKkxuat={setMaKkxuat}
          setNgayxuat={setNgayxuat}
          setMadoituong={setMadoituong}
          reloadData={reloadData}
          onOpenDetail={onOpenDetailHandler}
          onDelete={onDeleteHandler}
        />
      </MeditechTablePage>

      <ModalBanLe
        visibleModal={visibleModal}
        loading={false}
        setVisibleModal={setVisibleModal}
        setBanHangId={setBanHangId}
        banHangId={banHangId}
        banHangDetail={banHangDetail}
        reloadData={() => reloadData()}
        permitList={permitList}
        banHangCTList={banHangCTList}
        setMaKkxuat={setMaKkxuat}
        maKkxuat={maKkxuat}
        setNgayxuat={setNgayxuat}
        ngayxuat={ngayxuat}
        setMadoituong={setMadoituong}
        madoituong={madoituong}
        getBanHangCTByMabanhang={getBanHangCTByMabanhang}
      />
    </>
  );
};

export default BanLeList;
